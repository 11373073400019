import _ from 'lodash';

/* -------------------------------------------------------------------------- */
/*                                Local storage                               */
/* -------------------------------------------------------------------------- */

import { getAuthenticatorForRegion } from "../api/managers/servers";

/* ---------------------------------- Keys ---------------------------------- */

export const LOCAL_STORAGE_LAST_LIVE_LAYOUT = 'lastSelectedLiveFeedsLayout';
export const LOCAL_STORAGE_COLLAPSED_SIDEBAR = 'collapsedSidebar';
export const LOCAL_STORAGE_EVENTS_FILTERS = 'eventsFilters';
export const LOCAL_STORAGE_BANNER_CLOSED = 'bannerClosed';
export const LOCAL_STORAGE_SHOW_CAMERA_NAMES = 'showCamNames';
export const LOCAL_STORAGE_LIVE_STILLS_MODE = 'liveStillsMode';
export const LOCAL_STORAGE_CAMERA_NAVIGATOR_EXPANDED = 'cameraNavigatorExpanded';
export const LOCAL_STORAGE_CAMERA_NAVIGATOR_SELECTION = 'cameraNavigatorSelection';
export const LOCAL_STORAGE_LIVE_GRID_FILTERS = 'gridViewFilters';
export const LOCAL_STORAGE_VEHICLES_FILTERS = 'vehiclesFilters';
export const LOCAL_STORAGE_PEOPLE_COUNTING_FILTERS = 'peopleCountingFilters';
export const LOCAL_STORAGE_PLAYBACK_SELECTION = 'playbackSelection';
export const LOCAL_STORAGE_SETTINGS_SELECTION = 'settingsSelection';
export const LOCAL_STORAGE_PLAYBACK_TIME = 'playbackTime';
export const LOCAL_STORAGE_PLAYBACK_VISIBLE_RANGE = 'playbackTimelineVisibleRange';
export const LOCAL_STORAGE_SHOW_INSTALLER_WARNING = 'showInstallerWarning';
export const LOCAL_STORAGE_ORDERCAM_SELECTED_ORGS = 'ordercamSelectedOrgs';

/* --------------------------------- Helpers -------------------------------- */

const getPageFilterForLoggedInUser = localStorageKey => (loggedInUserUid, filter) => {
    if (localStorage[localStorageKey]) {
        try {
            return JSON.parse(localStorage[localStorageKey])?.[loggedInUserUid]?.[filter];
        } catch (error) {
            console.error(error);
        }
    }
    return undefined;
}

const setPageFilterForLoggedInUser = localStorageKey => (loggedInUserUid, filter, value) => {
    let filters = {};
    if (localStorage[localStorageKey]) {
        try {
            filters = JSON.parse(localStorage[localStorageKey]);
        } catch (error) {
            console.error(`Unable to parse ${localStorageKey} in local storage so overwriting. Error: ${error}`);
        }
    }
    if (!filters[loggedInUserUid]) {
        filters[loggedInUserUid] = {};
    }
    filters[loggedInUserUid][filter] = value;
    localStorage[localStorageKey] = JSON.stringify(filters);
};

const clearPageFiltersForLoggedInUser = localStorageKey => (loggedInUserUid) => {
    let filters = {};
    if (localStorage[localStorageKey]) {
        try {
            filters = JSON.parse(localStorage[localStorageKey]);
        } catch (error) {
            console.error(`Unable to parse ${localStorageKey} in local storage so overwriting. Error: ${error}`);
        }
    }
    delete filters[loggedInUserUid];
    localStorage[localStorageKey] = JSON.stringify(filters);
};



// Once all pages are in web app, we shouldn't need to clear local storage as it should only be used for values that need to persist between logins
export const clearLocalStorage = () => {
    // Local storage items that must not be removed
    const keysToKeep = [
        LOCAL_STORAGE_LAST_LIVE_LAYOUT,
        LOCAL_STORAGE_COLLAPSED_SIDEBAR,
        LOCAL_STORAGE_EVENTS_FILTERS,
        LOCAL_STORAGE_BANNER_CLOSED,
        LOCAL_STORAGE_SHOW_CAMERA_NAMES,
        LOCAL_STORAGE_LIVE_STILLS_MODE,
        LOCAL_STORAGE_CAMERA_NAVIGATOR_SELECTION,
        LOCAL_STORAGE_LIVE_GRID_FILTERS,
        LOCAL_STORAGE_CAMERA_NAVIGATOR_EXPANDED,
        LOCAL_STORAGE_PLAYBACK_SELECTION,
        LOCAL_STORAGE_SETTINGS_SELECTION,
        LOCAL_STORAGE_PLAYBACK_TIME,
        LOCAL_STORAGE_PLAYBACK_VISIBLE_RANGE,
        LOCAL_STORAGE_VEHICLES_FILTERS,
        LOCAL_STORAGE_PEOPLE_COUNTING_FILTERS,
        LOCAL_STORAGE_SHOW_INSTALLER_WARNING,
        LOCAL_STORAGE_ORDERCAM_SELECTED_ORGS
    ];

    // Get items before clearing storage so we can put them back after
    const itemsToKeep = keysToKeep.map((key) => ({
        key,
        data: localStorage.getItem(key),
    }));

    localStorage.clear();

    // Put data back that we want to keep
    itemsToKeep.forEach(({ key, data }) => {
        if (data) {
            localStorage.setItem(key, data);
        }
    });
};

// data is the object returned from successful login request
// webLogin, loginResponse, authenticator and portalusername get put in local storage
// Use this for email/password logins
export const updateLocalStorageOnLogin = (data) => {
    // Replace localStorage.webLogin completely
    localStorage.webLogin = JSON.stringify(data.webLogin);

    // Add loginResponse to local storage
    // Must not store authToken or managementToken
    const dataWithoutTokens = { ...data };
    delete dataWithoutTokens.authToken;
    delete dataWithoutTokens.managementToken;
    localStorage.loginResponse = JSON.stringify(dataWithoutTokens);

    // Add authenticator to local storage
    // This can be ditched when other web repos have been dropped
    localStorage.authenticator = getAuthenticatorForRegion(data.region);

    // Add portalusername to local storage
    // Portal gets user email from this (Why? No idea!)
    localStorage.portalusername = data.email;
};

// data is the object returned from successful login request
// loginToken gets updated in localStorage.webLogin and localStorage.loginResponse
export const updateLocalStorageOnRefreshToken = data => {
    // Just update webLogin (with new loginToken)
    let previousWebLogin = {};
    if (localStorage.webLogin) {
        try {
            previousWebLogin = JSON.parse(localStorage.webLogin);
        } catch {}
    }
    const webLogin = { ...previousWebLogin, ...data.webLogin };
    localStorage.webLogin = JSON.stringify(webLogin);

    // loginResponse also contains webLogin
    if (localStorage.loginResponse) {
        try {
            localStorage.loginResponse = JSON.stringify({ ...JSON.parse(localStorage.loginResponse), webLogin });
        } catch {}
    }
}

// Get seriesId, uid, token, and authenticator (if possible) from local storage
export const getLoginDetailsFromLocalStorage = () => {
    // Try to parse webLogin
    const details = JSON.parse(localStorage.webLogin);

    // Try to get authenticator from local storage
    if (localStorage.loginResponse) {
        try {
            details.authenticator = JSON.parse(
                localStorage.loginResponse
            ).authenticator;
        } catch (error) {}
    }

    return details;
};

// Camera navigator selection stored per page for each logged in uid
export const getCameraNavigatorSelectionForPageForLoggedInUser = (loggedInUserUid, page) => {
    if (localStorage[LOCAL_STORAGE_CAMERA_NAVIGATOR_SELECTION]) {
        try {
            return JSON.parse(localStorage[LOCAL_STORAGE_CAMERA_NAVIGATOR_SELECTION])?.[loggedInUserUid]?.[page];
        } catch (error) {
            console.error(error);
        }
    }
    return undefined;
};

/**
 * Update the stored camera navigator selection for a page.
 * @param {number} loggedInUserUid Logged in user's uid.
 * @param {string} page Page identifier to store selection against.
 * @param {string[]|null} selectedUidds New selection. To clear selection, this should be empty array or null.
 */
export const setCameraNavigatorSelectionForPageForLoggedInUser = (loggedInUserUid, page, selectedUidds) => {
    let gridViewCameraSelection = {};
    if (localStorage[LOCAL_STORAGE_CAMERA_NAVIGATOR_SELECTION]) {
        try {
            gridViewCameraSelection = JSON.parse(localStorage[LOCAL_STORAGE_CAMERA_NAVIGATOR_SELECTION]);
        } catch (error) {
            console.error(`Unable to parse ${LOCAL_STORAGE_CAMERA_NAVIGATOR_SELECTION} in local storage so overwriting. Error: ${error}`);
        }
    }

    if (!selectedUidds || selectedUidds.length === 0) {
        // Clear selection
        if (gridViewCameraSelection[loggedInUserUid]) {
            delete gridViewCameraSelection[loggedInUserUid][page];

            if (_.isEmpty(gridViewCameraSelection[loggedInUserUid])) {
                delete gridViewCameraSelection[loggedInUserUid];
            }
        }
    } else {
        // Update selection
        if (!gridViewCameraSelection[loggedInUserUid]) {
            gridViewCameraSelection[loggedInUserUid] = {};
        }

        gridViewCameraSelection[loggedInUserUid][page] = selectedUidds;
    }
    
    if (_.isEmpty(gridViewCameraSelection)) {
        // Nothing left to store
        localStorage.removeItem(LOCAL_STORAGE_CAMERA_NAVIGATOR_SELECTION);
    } else {
        // Update value
        localStorage[LOCAL_STORAGE_CAMERA_NAVIGATOR_SELECTION] = JSON.stringify(gridViewCameraSelection);
    }
};

/**
 * Get stored filter values for live grid view.
 * @param {number} loggedInUserUid Uid of logged in user.
 * @param {('status'|'tags')} filter Id of filter.
 * @returns {*} Value of specified filter for specified user. If no value is stored, undefined will be returned;
 */
export const getGridViewFilterForLoggedInUser = getPageFilterForLoggedInUser(LOCAL_STORAGE_LIVE_GRID_FILTERS);

/**
 * Store a value for one of live grid view's filters.
 * @param {number} loggedInUserUid Uid of logged in user.
 * @param {('status'|'tags')} filter Id of filter.
 * @param {*} value Value to store.
 */
export const setGridViewFilters = setPageFilterForLoggedInUser(LOCAL_STORAGE_LIVE_GRID_FILTERS);

/**
 * Remove all live grid view filters for the logged in user from local storage.
 * @param {number} loggedInUserUid Uid of logged in user.
 */
export const clearGridViewFilters = clearPageFiltersForLoggedInUser(LOCAL_STORAGE_LIVE_GRID_FILTERS);

/**
 * Get whether live camera navigator was expanded on user's last visit.
 * @returns {boolean} Was navigator expanded?
 */
export const getCameraNavigatorExpanded = () => {
    if (localStorage[LOCAL_STORAGE_CAMERA_NAVIGATOR_EXPANDED]) {
        return localStorage[LOCAL_STORAGE_CAMERA_NAVIGATOR_EXPANDED] === 'true';
    }
    return undefined;
}

/**
 * Update in local storage whether user has expanded the live camera navigator.
 * @param {boolean} expanded Is navigator expanded?
 */
export const setCameraNavigatorExpanded = (expanded) => {
    localStorage[LOCAL_STORAGE_CAMERA_NAVIGATOR_EXPANDED] = expanded;
};

export const getPlaybackTime = () => {
    const time = parseInt(localStorage[LOCAL_STORAGE_PLAYBACK_TIME]);
    return !isNaN(time) ? time : undefined;
}

export const setPlaybackTime = (time) => {
    localStorage[LOCAL_STORAGE_PLAYBACK_TIME] = time;
}

export const getPlaybackTimelineVisibleRangeSize = () => {
    const range = parseInt(localStorage[LOCAL_STORAGE_PLAYBACK_VISIBLE_RANGE]);
    return !isNaN(range) ? range : undefined;
}

export const setPlaybackTimelineVisibleRangeSize = (range) => {
    localStorage[LOCAL_STORAGE_PLAYBACK_VISIBLE_RANGE] = range;
}

export const setShowCameraNames = show => {
    localStorage[LOCAL_STORAGE_SHOW_CAMERA_NAMES] = show;
};

export const getShowCameraNames = () => 
    localStorage[LOCAL_STORAGE_SHOW_CAMERA_NAMES] === 'true' ? true :
    localStorage[LOCAL_STORAGE_SHOW_CAMERA_NAMES] === 'false' ? false : 
    false
;

/**
 * Store whether live grid page shows in stills mode in local storage.
 * @param {boolean} stillsMode New value.
 */
export const setLiveStillsMode = stillsMode => {
    localStorage[LOCAL_STORAGE_LIVE_STILLS_MODE] = stillsMode;
};

/**
 * Get stored value of live grid page stills/video toggle. Returns undefined if nothing is stored.
 * @returns {boolean|undefined} Stored value.
 */
export const getLiveStillsMode = () => {
    if (localStorage[LOCAL_STORAGE_LIVE_STILLS_MODE]) {
        return localStorage[LOCAL_STORAGE_LIVE_STILLS_MODE] === 'true';
    }
}

/**
 * Get stored value for the logged in user for one of the filters on the Vehicles page.
 * @param {number} loggedInUserUid Uid of logged in user.
 * @param {string} filter Filter key.
 * @returns {*} Filter value.
 */
export const getVehiclesFilterForLoggedInUser = getPageFilterForLoggedInUser(LOCAL_STORAGE_VEHICLES_FILTERS);

/**
 * Store a value for one of the filters on the Vehicles page.
 * @param {number} loggedInUserUid Uid of logged in user.
 * @param {('cameras'|'dateRange')} filter Id of filter.
 * @param {*} value Value to store.
 */
export const setVehiclesFilter = setPageFilterForLoggedInUser(LOCAL_STORAGE_VEHICLES_FILTERS);

/**
 * Clear all stored filters for the logged in user on the Vehicles page.
 * @param {number} loggedInUserUid Uid of logged in user.
 */
export const clearVehiclesFilters = clearPageFiltersForLoggedInUser(LOCAL_STORAGE_VEHICLES_FILTERS);

/**
 * Get stored value for the logged in user for one of the filters on the People Counting page.
 * @param {number} loggedInUserUid Uid of logged in user.
 * @param {string} filter Filter key.
 * @returns {*} Filter value.
 */
export const getPeopleCountingFilterForLoggedInUser = getPageFilterForLoggedInUser(LOCAL_STORAGE_PEOPLE_COUNTING_FILTERS);

/**
 * Store a value for one of the filters on the People Counting page.
 * @param {number} loggedInUserUid Uid of logged in user.
 * @param {('cameras'|'dateRange')} filter Id of filter.
 * @param {*} value Value to store.
 */
export const setPeopleCountingFilter = setPageFilterForLoggedInUser(LOCAL_STORAGE_PEOPLE_COUNTING_FILTERS);

/**
 * Clear all stored filters for the logged in user on the People Counting page.
 * @param {number} loggedInUserUid Uid of logged in user.
 */
export const clearPeopleCountingFilters = clearPageFiltersForLoggedInUser(LOCAL_STORAGE_PEOPLE_COUNTING_FILTERS);

export const getSavedViewerTimezone = () => localStorage.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getHideInstallerWarningPreference = () => JSON.parse(localStorage[LOCAL_STORAGE_SHOW_INSTALLER_WARNING]);

export const setHideInstallerWarningPreference = () => {
    localStorage[LOCAL_STORAGE_SHOW_INSTALLER_WARNING] = false;
}

/**
 * Get the last selected organisation ids used by ordercam from local storage.
 * @returns {number[]|void} Returns ids if stored values were found.
 */
export const getOrdercamSelectedOrganisations = () => {
    if (localStorage[LOCAL_STORAGE_ORDERCAM_SELECTED_ORGS]) {
        try {
            const stored = JSON.parse(localStorage[LOCAL_STORAGE_ORDERCAM_SELECTED_ORGS]);
            if (Array.isArray(stored)) {
                return stored;
            }
        } catch (error) {
            console.error(error);
        }
    }
}

/**
 * Update selected ordercam organisations in local storage.
 * @param {number[]} organisationIds Ids of selected organisations.
 */
export const setOrdercamSelectedOrganisations = (organisationIds) => {
    localStorage[LOCAL_STORAGE_ORDERCAM_SELECTED_ORGS] = JSON.stringify(organisationIds);
}


/* -------------------------------------------------------------------------- */
/*                               Session storage                              */
/* -------------------------------------------------------------------------- */

const SESSION_STORAGE_LOGGED_IN_REDIRECT_KEY = 'loggedInRedirect';

export const setLoggedInRedirectInSessionStorage = location => {
    sessionStorage[SESSION_STORAGE_LOGGED_IN_REDIRECT_KEY] = location;
}

export const getLoggedInRedirectInSessionStorage = () => sessionStorage[SESSION_STORAGE_LOGGED_IN_REDIRECT_KEY];

export const clearLoggedInRedirectInSessionStorage = () => {
    sessionStorage.removeItem(SESSION_STORAGE_LOGGED_IN_REDIRECT_KEY);
}
