import ErrorStackParser from 'error-stack-parser';
import StackTraceGPS from 'stacktrace-gps';

import { getUid } from '../api/managers/store';
import { getTimeTokensLastUpdated } from '../api/managers/requestManager';

import { logErrorToAthena } from '../api/athena';
import { postErrorInSlack } from '../api/errors';

import { isDevelopment } from './getHost';
import { WEB_APP_VERSION } from './version';

// Min time between logged error messages (for both Athena and Slack) in ms
const LOG_ERROR_COOLDOWN = 10000;

/*
    Info on last error logged.
    Shape:
        {
            time: timestamp in ms (used to limit how often web can log),
            message: string (used to avoid duplicates)
        }
*/
let lastErrorLog = null;

/**
 * 
 * @param {Error} error Error thrown.
 * @param {object|string} [info] Additional information. Providing a string is shorthand for `{ identifier: <string> }`.
 * @param {string} [info.identifier] Identifier for where error came from.
 */
export const logError = (error, info) => {
    console.error(error);

    // At the moment we can't log to Athena unless user is logged in
    if (
        getUid() &&
        !isDevelopment &&

        // Discard message if we are still in cooldown period
        new Date().getTime() - (lastErrorLog?.time ?? 0) >= LOG_ERROR_COOLDOWN
    ) {
        const mapAndLogError = async () => {
            try {

                if (
                    lastErrorLog?.message === error.message &&
                    new Date().getTime() - lastErrorLog?.time < 30000
                ) {
                    // Avoid duplicates
                    return;
                }

                const frames = ErrorStackParser.parse(error);

                const gps = new StackTraceGPS();

                const mappedFrames = await Promise.all(
                    frames.slice(0,5).map(frame => gps.pinpoint(frame).then(stackFrame => {
                        return {
                            ...stackFrame,
                            // WAF rules will block any requests containing file paths starting with `../`
                            fileName: stackFrame.fileName.replace(/^\.\.\//g, '/')
                        };
                    }).catch((error) => {
                        console.error(error);
                        return { error: 'error mapping frame' };
                    }))
                );

                const infoToLog = typeof info === 'object' ? info : { identifier: info };
                infoToLog.webAppVersion = WEB_APP_VERSION;

                // Extra info for failed requests
                if (error.isAxiosError) {
                    infoToLog.requestURL = error.config?.url;
                    infoToLog.responseData = error.response?.data;
                    if (error.response?.status === 401) {
                        const timeTokensLastUpdated = getTimeTokensLastUpdated();
                        infoToLog.minutesSinceLastTokenUpdate = timeTokensLastUpdated ? (new Date().getTime() - timeTokensLastUpdated.getTime()) / 60000 : null;
                    }
                }

                lastErrorLog = {
                    time: new Date().getTime(),
                    message: error.message
                };

                logErrorToAthena(error.message, mappedFrames, infoToLog);

                // Post message in Slack
                if (
                    // Exlude certain errors that we are not interested in
                    !(
                        // Network error
                        /^Network Error/.test(error.message) ||
                        // Loading chunk (chunks get replaced on each new version release)
                        /^Loading chunk/.test(error.message) ||
                        // Errors probably from Google translate
                        /^Failed to execute '(insertBefore|removeChild)' on 'Node'/.test(error.message) ||
                        error.message === 'Node.removeChild: The node to be removed is not a child of this node' ||
                        // Event video and high res still 404s - we currently get lots of these and they're not a frontend issue
                        (infoToLog.identifier === 'EventPlayer - fetch high res still' && error.message === 'Request failed with status code 404') ||
                        (infoToLog.identifier === 'EventPlayer - fetch event video' && error.message === 'Request failed with status code 404') ||
                        (infoToLog.identifier === 'EventPlayer - download' && error.message === 'Request failed with status code 404') ||
                        (infoToLog.identifier === 'Event tile - fetch mouseover event video' && error.message === 'Request failed with status code 404') ||
                        // Video service 500s are usually a chunk issue and obviously nothing to do with frontend
                        (infoToLog.identifier === 'EventPlayer - fetch event video' && error.message === 'Request failed with status code 500') ||
                        (infoToLog.identifier === 'Event tile - fetch mouseover event video' && error.message === 'Request failed with status code 500') ||
                        (infoToLog.identifier === 'Playback - build chunk' && error.message === 'Request failed with status code 500') ||
                        (infoToLog.identifier === 'EventsList - download selected events' && error.message === 'Request failed with status code 500') ||
                        // Event not found errors probably due to expired cloud storage (definitely not front end)
                        (infoToLog.identifier === 'EventPlayer - fetch event data' && error.message === 'Not found') ||
                        // Realtime errors
                        (infoToLog.identifier === 'SSE') ||
                        // Aborted requests aren't an issue - some browsers do this if user leaves page
                        (error.isAxiosError && error.message === 'Request aborted') ||

                        // Temporarily pausing these ones
                        // Need to look into why these requests can return 401 but the token is still valid to log the error
                        (infoToLog.identifier === 'Event tile - fetch mouseover event video' && error.message === 'Request failed with status code 401') ||
                        (infoToLog.identifier === 'useRealtimeCameraStatuses - update statuses' && error.message === 'Request failed with status code 401') ||
                        // Also I'm not sure what causes "timeout exceeded"
                        (error.isAxiosError && error.message === 'timeout exceeded')
                    )
                ) {
                    postErrorInSlack(error.message, mappedFrames, infoToLog);
                }

            } catch (error) {
                console.error(error);
            }
        }

        mapAndLogError();
    }
}